import React from 'react';

import ConnectedDatePicker from 'src/pages/search/[country]/[type]/ConnectedDatePicker';
import ConnectedSuggester from 'src/pages/search/[country]/[type]/SuggesterConnected';
import PaxesConnected from 'src/pages/search/[country]/[type]/PaxesConnected';
import { SearchMode, SearchUrl } from 'src/types/search';

import { OmniSearchBase, OmniSearchOverlay, OmniSearchContent, OmniSearchWrapper } from './styles';

import { setSearchParamsLocalStorage } from 'lib/storage';
import { getPaxesfromUrlParams } from 'client/utils';

import SearchIcon from 'client/icons/Search';
import SearchButton from '../SearchButton';
import { useBusiness } from 'src/hooks/useBusiness';
import { AFFILIATE_EBOOKING } from 'src/constants/affiliates';

interface IProps {
  mode: SearchMode;
  onChangeMode: (mode: SearchMode) => void;
  preventNavigation?: boolean;
  searchUrl: SearchUrl;
}

const OmniSearch = ({ mode, onChangeMode, preventNavigation = true, searchUrl }: IProps) => {
  const { href } = searchUrl;
  const { affiliate } = useBusiness();
  const isEbooking = affiliate === AFFILIATE_EBOOKING;

  const handleSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onChangeMode(SearchMode.Readonly);

    const paxes = getPaxesfromUrlParams({
      adults: href.query.adults,
      children: href.query.children,
    });

    setSearchParamsLocalStorage({
      checkIn: href.query.checkin,
      checkOut: href.query.checkout,
      adults: paxes.adults,
      children: paxes.children,
    });
  };

  return (
    <>
      {mode === SearchMode.Full && (
        <OmniSearchOverlay onClick={() => onChangeMode(SearchMode.Readonly)} />
      )}
      <OmniSearchBase
        className={mode}
        onClick={() => onChangeMode(SearchMode.Full)}
        data-testid="omni-search"
        isEbooking={isEbooking}
      >
        {mode === SearchMode.Readonly && <SearchIcon data-testid="search-icon" size={20} />}
        <OmniSearchWrapper>
          <ConnectedSuggester mode={mode} className="suggester-wrapper" />
          <OmniSearchContent className={mode}>
            <ConnectedDatePicker mode={mode} />
            <PaxesConnected mode={mode} />
          </OmniSearchContent>
        </OmniSearchWrapper>
        {mode !== SearchMode.Readonly && (
          <SearchButton
            onClick={handleSearch}
            fullWidth={mode === SearchMode.Full}
            preventNavigation={preventNavigation}
            searchUrl={searchUrl}
          />
        )}
      </OmniSearchBase>
    </>
  );
};

export default OmniSearch;
