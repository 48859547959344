import React, { useRef, useState } from 'react';
import { SuggestionType, SearchMode } from 'src/types/search';
import { SuggestionWrapper, Suggestions } from './styles';

import { SuggesterReadOnly } from './modes/ReadOnly';
import { SuggesterBorderLess } from './modes/BorderLess';
import { SuggesterFull } from './modes/Full';
import Suggestion from './Suggestion';
import { useClickAway } from 'react-use';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useKafkaEvent } from 'src/hooks/useKafkaEvent';
import { useSelector } from 'react-redux';
import { SECTIONS } from 'src/hooks/useKafkaEvent/constants';
import { getSuggesterSearch } from 'src/effects/Suggester/selectors';
import { usePage } from 'src/hooks/usePage';

interface ISuggesterProps {
  readonly mode: SearchMode;
  readonly className?: string;
  readonly suggestion?: SuggestionType | null;
  readonly suggestions: SuggestionType[];
  readonly onSelect: (value: SuggestionType | null) => void;
  readonly onChangeInput: (search: string) => void;
}

const suggesterConfig = {
  [SearchMode.Readonly]: SuggesterReadOnly,
  [SearchMode.Borderless]: SuggesterBorderLess,
  [SearchMode.Full]: SuggesterFull,
};

function Suggester({ mode, suggestions, onSelect, className = '', ...props }: ISuggesterProps) {
  const suggesterRef = useRef<HTMLDivElement | null>(null);
  const [hasFocus, setHasFocus] = useState(false);

  const { pageId } = usePage();

  const { sendEvent } = useKafkaEvent({ page: pageId });
  const suggesterSearch = useSelector(getSuggesterSearch);

  useClickAway(suggesterRef, () => {
    setHasFocus(false);
    if (suggesterRef.current) {
      enableBodyScroll(suggesterRef.current);
    }
  });

  const SuggesterVariant = suggesterConfig[mode];

  const handleFocus = () => {
    sendEvent({ section: SECTIONS.SUGGESTER });
    setHasFocus(true);
    if (suggesterRef.current) {
      disableBodyScroll(suggesterRef.current);
    }
  };

  const createSelectHandler = (suggestion: SuggestionType, index: number) => () => {
    onSelect(suggestion);
    const { Text, Description, ID, Type } = suggestion;
    sendEvent(
      { section: SECTIONS.SUGGESTION },
      { suggestion: { Text, Description, ID, Type }, search: suggesterSearch, index }
    );
    setHasFocus(false);
    if (suggesterRef.current) {
      enableBodyScroll(suggesterRef.current);
    }
  };

  return (
    <SuggestionWrapper
      mode={mode}
      hasFocus={hasFocus}
      onFocus={handleFocus}
      ref={suggesterRef}
      className={`${className} ${hasFocus ? 'has-focus' : ''}`}
      data-testid="suggester-wrapper"
    >
      <SuggesterVariant
        {...props}
        onSelect={onSelect}
        hasFocus={hasFocus}
        onClose={() => setHasFocus(false)}
      />

      {mode !== SearchMode.Readonly && suggestions.length > 0 && hasFocus && (
        <Suggestions className={mode} hasFocus={hasFocus}>
          {suggestions.map((suggestion, index) => (
            <Suggestion
              key={suggestion.ID}
              item={suggestion}
              onClick={createSelectHandler(suggestion, index)}
            />
          ))}
        </Suggestions>
      )}
    </SuggestionWrapper>
  );
}

export default Suggester;
